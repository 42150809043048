export default [
  {
    header: 'Kinh doanh',
    resource: 'Subscription',
    action: 'read',
  },
  {
    title: 'Gói dịch vụ',
    route: 'pricing-list',
    icon: 'PackageIcon',
    resource: 'Pricing',
    action: 'read',
  },
  {
    title: 'Mã kích hoạt',
    route: 'code-list',
    icon: 'CodeIcon',
    resource: 'Code',
    action: 'read',
  },
  {
    title: 'Voucher',
    route: 'voucher-list',
    icon: 'GiftIcon',
    resource: 'Voucher',
    action: 'read',
  },
  {
    title: 'Ưu đãi',
    route: 'discount-list',
    icon: 'PercentIcon',
    resource: 'Discount',
    action: 'read',
  },
  {
    title: 'Lịch sử thanh toán',
    route: 'payment-list',
    icon: 'CreditCardIcon',
    resource: 'Payment',
    action: 'read',
  },
];
