export default [
  {
    header: 'Cài đặt hệ thống',
    resource: 'Setting',
    action: 'read',
  },
  {
    title: 'Cài đặt chung',
    icon: 'SettingsIcon',
    children: [
      {
        title: "Khoa",
        route: "department-list",
        resource: 'Department',
        action: 'read',
      },
      {
        title: "Chuyên khoa",
        route: "specialist-list",
        resource: 'Specialist',
        action: 'read',
      },
      {
        title: "Nhóm máu",
        route: "blood-group-list",
        resource: 'Blood',
        action: 'read',
      },
      {
        title: "Dịch vụ cung cấp",
        route: "service-list",
        resource: 'Service',
        action: 'read',
      },
      {
        title: "Bệnh tật",
        route: "disease-list",
        resource: 'Disease',
        action: 'read',
      },
      {
        title: "Thuốc",
        route: "drug-list",
        resource: 'Drug',
        action: 'read',
      },
      {
        title: "Câu hỏi tiền khám bệnh",
        resource: 'Faq',
        action: 'read',
        route: "faq-list",
      },
    ],

  },
  {
    title: 'Truyền thông',
    icon: 'MonitorIcon',
    route: 'advertisement-list',
    resource: 'SettingAdvertisement',
    action: 'read',
  },
  {
    title: 'Cấu hình',
    icon: 'ServerIcon',
    route: 'configuration-list',
    resource: 'SettingConfiguration',
    action: 'read',
  },

];
