export default [
  {
    header: 'Người dùng',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Bác sĩ',
    route: 'doctor-list',
    icon: 'UsersIcon',
    resource: 'Doctor',
    action: 'read',
  },
  {
    title: 'Bệnh nhân',
    route: 'patient-list',
    icon: 'UserCheckIcon',
    resource: 'Patient',
    action: 'read',
  },
];
