export default [
  {
    header: 'Cơ sở',
    resource: 'Place',
    action: 'read',
  },
  {
    title: 'Cơ sở khám bệnh',
    route: 'facility-list',
    icon: 'HomeIcon',
    resource: 'Facility',
    action: 'read',
  },
  // {
  //   title: 'Hiệu thuốc',
  //   route: 'drug-store-list',
  //   icon: 'ShoppingBagIcon',
  //   resource: 'Pharmacy',
  //   action: 'read',
  // },
];
