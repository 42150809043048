export default [
  {
    header: 'Vận hành',
    resource: 'Operation',
    action: 'read',
  },
  {
    title: 'Thời gian làm việc',
    route: 'attendance-list',
    icon: 'CalendarIcon',
    resource: 'Attendance',
    action: 'read',
  },
  {
    title: 'Lịch sử khám bệnh',
    route: 'history-list',
    icon: 'ThermometerIcon',
    resource: 'History',
    action: 'read',
  },
  {
    title: 'Quản lý chất lượng',
    route: 'review-list',
    icon: 'StarIcon',
    resource: 'Review',
    action: 'read',
  },
  {
    title: 'Đơn thuốc',
    route: 'prescription-list',
    icon: 'FileTextIcon',
    resource: 'Prescription',
    action: 'read',
  },
];
